@import url('https://fonts.googleapis.com/css?family=Heebo:400,700|Open+Sans:400,700');


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Open Sans';
  background: #fafafa;
}

h3 {
  color: var(--color-tournament-green);
}

a {
  color: inherit;
}

.card {
  font-family: 'Heebo';
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 7em;
  width: 7em;
  font-size: 1.5em;
  color: #ebecea;
  border-radius: 1em;
  padding: .3em;
  display: flex;
  align-items: flex-end;
  background-color: #FFFFFF;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

[id^="infowindow"] {
  background-color: #FFFFFF;
  border-radius: 1.5em;
}

.card .container {
  position: absolute;
  padding: .3em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.card h1 {
  margin: 0;
  padding-top: 1em;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
}

.card p {
  font-size: 0.7em;
  font-family: 'Open Sans';
  margin-top: 0.5em;
  line-height: 1.7em;
  padding: .5em;
  padding-left: .4em;
}

.card .tags {
  display: flex;
}

.card .tags .tag {
  position: absolute;
  bottom: 1em;
  left: 1em;
  font-size: 0.55em;
  background: var(--color-tournament-green);
  opacity: .9;
  border-radius: 0.3rem;
  padding: 0 .3em;
  margin-right: 0.5em;
  line-height: 1.5em;
}

.card:hover .tags .tag {
  background: var(--color-tournament-green);
  color: #ebecea;
}

.card .date {
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 0.5em;
  padding: 0em;
  padding-left: 1em;
  line-height: .5em;
  opacity: .9;
}

.card:hover {
  color: var(--color-tournament-green);
}

.info {
  font-size: 1.2em;
  display: flex;
  padding: 1em 3em;
  height: 3em;
}

.info img {
  height: 3em;
  margin-right: 0.5em;
}

.info h1 {
  font-size: 1em;
  font-weight: normal;
}


@media screen and (max-width: 900px) {

  .info {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {

  .card {
    max-width: calc(100vw - 4rem);
  }
}

@media screen and (max-width: 450px) {
  .info {
    display: block;
    text-align: center;
  }
  .info h1 {
    margin: 0;
  }
} 

