.centeredColumnContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    gap: 2rem;
    height: 100%;
    padding-top: 2em;
    background: #7f7fd5;
    background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);
  }