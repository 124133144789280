@import url(https://fonts.googleapis.com/css?family=Heebo:400,700|Open+Sans:400,700);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Open Sans';
  background: #fafafa;
}

h3 {
  color: var(--color-tournament-green);
}

a {
  color: inherit;
}

.card {
  font-family: 'Heebo';
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 7em;
  width: 7em;
  font-size: 1.5em;
  color: #ebecea;
  border-radius: 1em;
  padding: .3em;
  display: flex;
  align-items: flex-end;
  background-color: #FFFFFF;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

[id^="infowindow"] {
  background-color: #FFFFFF;
  border-radius: 1.5em;
}

.card .container {
  position: absolute;
  padding: .3em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.card h1 {
  margin: 0;
  padding-top: 1em;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
}

.card p {
  font-size: 0.7em;
  font-family: 'Open Sans';
  margin-top: 0.5em;
  line-height: 1.7em;
  padding: .5em;
  padding-left: .4em;
}

.card .tags {
  display: flex;
}

.card .tags .tag {
  position: absolute;
  bottom: 1em;
  left: 1em;
  font-size: 0.55em;
  background: var(--color-tournament-green);
  opacity: .9;
  border-radius: 0.3rem;
  padding: 0 .3em;
  margin-right: 0.5em;
  line-height: 1.5em;
}

.card:hover .tags .tag {
  background: var(--color-tournament-green);
  color: #ebecea;
}

.card .date {
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 0.5em;
  padding: 0em;
  padding-left: 1em;
  line-height: .5em;
  opacity: .9;
}

.card:hover {
  color: var(--color-tournament-green);
}

.info {
  font-size: 1.2em;
  display: flex;
  padding: 1em 3em;
  height: 3em;
}

.info img {
  height: 3em;
  margin-right: 0.5em;
}

.info h1 {
  font-size: 1em;
  font-weight: normal;
}


@media screen and (max-width: 900px) {

  .info {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {

  .card {
    max-width: calc(100vw - 4rem);
  }
}

@media screen and (max-width: 450px) {
  .info {
    display: block;
    text-align: center;
  }
  .info h1 {
    margin: 0;
  }
} 


#legend {
  background-color:rgba(255, 255, 255, 0.5);
  padding: 1em;
  margin-right: 1em;
}

.legend_item {
  padding: .1em;
}
.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  #map {
    width: 100%;
    height: 100%;
  }
  
  .gm-style iframe + div { 
    border:none!important; 
  }


.mapViewContainer {
    position: absolute;
    padding: 0em;
    width: 100vw;
    height: 100vh;
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
}

.mapContainer {
    padding: 0em;
    /* background-color: #CCCCCC; */
    width: 100%;
    height: 100%;
}

.legendContainer {
    padding: 0em;
    padding-left: 1em;
    width: 100%;
    height: 100%;
}


.ui.menu {
    font-size: 1rem;
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex; */
    display: table;
    /* margin: 1rem 0; */
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    background: #fff;
    font-weight: 400;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    border-radius: 0.28571429rem;
    height: 2em;
    width: 100%;
}

.ui.menu .item {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    flex: 0 0 auto;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: 0 0;
    padding: 0.5em;
    text-transform: none;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    transition: background .1s ease,box-shadow .1s ease,color .1s ease;
}

.ui.menu .item:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: rgba(34,36,38,.1);
}

.ui.menu:last-child {
    margin-bottom: 0;
}
.ui.menu:first-child {
    margin-top: 0;
}

.ui.menu:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

*, :after, :before {
    box-sizing: inherit;
}

.ui.menu>.item:first-child {
    border-radius: 0.28571429rem 0 0 0.28571429rem;
}

/* .ui.menu:not(.vertical) .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
} */

.ui.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 0;
}

.ui.grid>.row {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

  @media screen and (max-width: 768px) {
    #id_siteHeader label {
      display: inline-block;
      color: #000;
      background: #fff;
      font-style: normal;
      font-size: 1.2em;
      padding: 10px;
    }
  
    #id_menuItems .item {
      display: block;
      background: #fff;
      border-bottom: 1px solid #ccc;
      height: 49px;
    }
  
    #id_menuItems {
      display: none;
    }
  
    #id_siteHeader input:checked ~ #id_menuItems {
      display: block;
    }
  }
  

img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.chessSiteCard {
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(22rem + 2vw), 25rem);
  overflow: hidden;
  box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #ECE9E6;
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
}

.chessSiteCard__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: .5rem;
  gap: .5rem;
}


.tag {
  align-self: flex-start;
  padding: .25em .75em;
  border-radius: 1em;
  font-size: .75rem;
}

.tag + .tag {
  margin-left: .5em;
}

.tag-blue {
  background: #56CCF2;
    background: linear-gradient(to bottom, #2F80ED, #56CCF2);
  color: #000000;
}

.tag-brown {
  background: #D1913C;
background: linear-gradient(to bottom, #FFD194, #D1913C);
  color: #000000;
}

.tag-red {
  background: var(--color-tournament-green);
  /* background: linear-gradient(to bottom, #ef473a, #cb2d3e); */
  color: #fafafa;
}

.chessSiteCard__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.chessSiteCard__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  grid-gap: .5rem;
  gap: .5rem;
}

.user__image {
  border-radius: 50%;
}

.user__info > small {
  color: #666;
}

.chessSiteContainer {
  display: flex;
  height: 20em;
}

/* images */
*
  box-sizing border-box

body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.classSiteImageContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 0.5rem;
}

.classSiteImage {
    position: relative;
    padding-bottom: 100%;
}

.classSiteImage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0;
    position: absolute;
    top: 0;
}
/* 
.classSiteImage img:nth-of-type(1) {
    filter: grayscale(1) brightness(40%);
}

.classSiteImage img:nth-of-type(1):hover  {
    filter: none;
}
     */
#chessSiteMap {
    width: 100%;
    height: 100%;
}
  
.gm-style iframe + div { 
    border:none!important; 
}

.chessSiteMap {
    padding: 0em;
    width: 100%;
    height: 100%;
}



.cardStatIconContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 1fr);
    width: 20em;
    grid-gap: 0.5rem;
}
  
  .cardStatIcon {
    position: relative;
    padding-bottom: 2.3em;
    max-width: 2em;
    max-height: 2em;
}
  
  .cardStatIcon img {

    object-fit: cover;
    left: 0;
    position: absolute;
    top: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Quicksand", sans-serif;
  height: 100vh;
  background: #7f7fd5;
  background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);
}

.chessSiteCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  grid-gap: 2rem;
  gap: 2rem;
  height: 100%;
  padding-top: 2em;
  background: #7f7fd5;
  background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);
}


.chessSiteInfoColumn {
    flex: 1 1;
    flex-basis: 30vw;
	flex-grow: 0;
	flex-shrink: 0;
    height: 100%;
}

.chessSiteMapColumn {
    flex: 1 1;
    flex-basis: 70vw;
	flex-grow: 0;
	flex-shrink: 0;
    height: 100%;
}

.chessSiteColumn:first-child {
    margin-right: 10px;
}
.centeredColumnContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    grid-gap: 2rem;
    gap: 2rem;
    height: 100%;
    padding-top: 2em;
    background: #7f7fd5;
    background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);
  }

.avatar {
    float: left;
    margin: .5em;
    width: 15em;
}

p {
    text-align: justify;
}

:root {
  --color-tournament-green: #356a4d;
}

html {
  font-size: 14px;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-width: 320px;
  background: #fff;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0,0,0,.87);
  font-smoothing: antialiased;
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0,0,0,.25);
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}
body ::-webkit-scrollbar-track {
  background: rgba(0,0,0,.1);
  border-radius: 0;
}
::selection {
  background-color: #cce2ff;
  color: rgba(0,0,0,.87);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

