#chessSiteMap {
    width: 100%;
    height: 100%;
}
  
.gm-style iframe + div { 
    border:none!important; 
}

.chessSiteMap {
    padding: 0em;
    width: 100%;
    height: 100%;
}
