
.avatar {
    float: left;
    margin: .5em;
    width: 15em;
}

p {
    text-align: justify;
}
