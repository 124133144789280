.mapViewContainer {
    position: absolute;
    padding: 0em;
    width: 100vw;
    height: 100vh;
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
}

.mapContainer {
    padding: 0em;
    /* background-color: #CCCCCC; */
    width: 100%;
    height: 100%;
}

.legendContainer {
    padding: 0em;
    padding-left: 1em;
    width: 100%;
    height: 100%;
}
