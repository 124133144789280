

.cardStatIconContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 1fr);
    width: 20em;
    grid-gap: 0.5rem;
}
  
  .cardStatIcon {
    position: relative;
    padding-bottom: 2.3em;
    max-width: 2em;
    max-height: 2em;
}
  
  .cardStatIcon img {

    object-fit: cover;
    left: 0;
    position: absolute;
    top: 0;
}