
/* images */
*
  box-sizing border-box

body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.classSiteImageContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 0.5rem;
}

.classSiteImage {
    position: relative;
    padding-bottom: 100%;
}

.classSiteImage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0;
    position: absolute;
    top: 0;
}
/* 
.classSiteImage img:nth-of-type(1) {
    filter: grayscale(1) brightness(40%);
}

.classSiteImage img:nth-of-type(1):hover  {
    filter: none;
}
     */