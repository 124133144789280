
img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.chessSiteCard {
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(22rem + 2vw), 25rem);
  overflow: hidden;
  box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #ECE9E6;
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
}

.chessSiteCard__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}


.tag {
  align-self: flex-start;
  padding: .25em .75em;
  border-radius: 1em;
  font-size: .75rem;
}

.tag + .tag {
  margin-left: .5em;
}

.tag-blue {
  background: #56CCF2;
    background: linear-gradient(to bottom, #2F80ED, #56CCF2);
  color: #000000;
}

.tag-brown {
  background: #D1913C;
background: linear-gradient(to bottom, #FFD194, #D1913C);
  color: #000000;
}

.tag-red {
  background: var(--color-tournament-green);
  /* background: linear-gradient(to bottom, #ef473a, #cb2d3e); */
  color: #fafafa;
}

.chessSiteCard__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.chessSiteCard__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  gap: .5rem;
}

.user__image {
  border-radius: 50%;
}

.user__info > small {
  color: #666;
}

.chessSiteContainer {
  display: flex;
  height: 20em;
}