
.chessSiteInfoColumn {
    flex: 1;
    flex-basis: 30vw;
	flex-grow: 0;
	flex-shrink: 0;
    height: 100%;
}

.chessSiteMapColumn {
    flex: 1;
    flex-basis: 70vw;
	flex-grow: 0;
	flex-shrink: 0;
    height: 100%;
}

.chessSiteColumn:first-child {
    margin-right: 10px;
}