.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  #map {
    width: 100%;
    height: 100%;
  }
  
  .gm-style iframe + div { 
    border:none!important; 
  }

