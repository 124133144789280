:root {
  --color-tournament-green: #356a4d;
}

html {
  font-size: 14px;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-width: 320px;
  background: #fff;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0,0,0,.87);
  font-smoothing: antialiased;
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0,0,0,.25);
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}
body ::-webkit-scrollbar-track {
  background: rgba(0,0,0,.1);
  border-radius: 0;
}
::selection {
  background-color: #cce2ff;
  color: rgba(0,0,0,.87);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
