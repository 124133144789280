
.ui.menu {
    font-size: 1rem;
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex; */
    display: table;
    /* margin: 1rem 0; */
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    background: #fff;
    font-weight: 400;
    border: 1px solid rgba(34,36,38,.15);
    -webkit-box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    border-radius: 0.28571429rem;
    height: 2em;
    width: 100%;
}

.ui.menu .item {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: 0 0;
    padding: 0.5em;
    text-transform: none;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    -webkit-transition: background .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
    transition: background .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
    transition: background .1s ease,box-shadow .1s ease,color .1s ease;
    transition: background .1s ease,box-shadow .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
}

.ui.menu .item:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: rgba(34,36,38,.1);
}

.ui.menu:last-child {
    margin-bottom: 0;
}
.ui.menu:first-child {
    margin-top: 0;
}

.ui.menu:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

*, :after, :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.ui.menu>.item:first-child {
    border-radius: 0.28571429rem 0 0 0.28571429rem;
}

/* .ui.menu:not(.vertical) .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
} */

.ui.grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 0;
}

.ui.grid>.row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

  @media screen and (max-width: 768px) {
    #id_siteHeader label {
      display: inline-block;
      color: #000;
      background: #fff;
      font-style: normal;
      font-size: 1.2em;
      padding: 10px;
    }
  
    #id_menuItems .item {
      display: block;
      background: #fff;
      border-bottom: 1px solid #ccc;
      height: 49px;
    }
  
    #id_menuItems {
      display: none;
    }
  
    #id_siteHeader input:checked ~ #id_menuItems {
      display: block;
    }
  }
  